import React, {Component} from "react"
import {navigate} from "@reach/router";


const LINE_WIDTH = 4;
const WIDTH = 35;
const HEIGHT = 35;
const OFFSET = 5;

class BackButton extends Component {
  constructor(props) {
    super(props)
    this.canvasEl = React.createRef()
    this.animationStart = undefined;
  }

  componentDidMount() {
    window.requestAnimationFrame(this.draw)
  }

  draw = (timestamp) => {
    if (this.animationStart === undefined) {
      this.animationStart = timestamp;
    }

    const elapsed = timestamp - this.animationStart

    let progress = elapsed * 0.003;
    if (progress >= 1) {
      progress = 1
    }

    const canvas = this.canvasEl.current;
    if (!canvas) {
      return;
    }
    const ctx = canvas.getContext('2d');

    const width = WIDTH * window.devicePixelRatio;
    const height = HEIGHT * window.devicePixelRatio;
    const offset = OFFSET * window.devicePixelRatio;

    canvas.style.width = `${WIDTH}px`;
    canvas.style.height = `${HEIGHT}px`;

    canvas.width = width;
    canvas.height = height;

    ctx.strokeStyle = `rgb(0, 255, 0)`;
    ctx.lineWidth = LINE_WIDTH;
    ctx.beginPath()
    ctx.moveTo(offset, offset)
    ctx.lineCap = 'round';
    ctx.lineTo(width - offset, (height - offset) * progress)
    ctx.moveTo((width - offset) * progress, offset)
    ctx.lineTo(offset, (height - offset) * progress)
    ctx.stroke()

    if (progress < 1) {
      window.requestAnimationFrame(this.draw)
    }
  }

  goBack = () => {
    navigate('../')
      .catch(e => {
        console.error(e)
        console.log('Failed to navigate back')
      })
  }

  render() {
    return (
      <button
        className="backbutton"
        onClick={this.goBack}
      >
        <canvas
          ref={this.canvasEl}
          className="oval-nav-canvas"
        />
      </button>
    )
  }
}

export default BackButton
