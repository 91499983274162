import React from 'react';
import "./main.scss"
import Helmet from "react-helmet";
import content from "../content/meta.json";
import {withPrefix} from "gatsby-link";
import OvalNav from "./OvalNav";
import BackButton from "./BackButton";

const Layout = ({children, title, noNav, noDummy, backButton}) => {
  return (
    <div>
      <Helmet>
        <html lang="en"/>
        <title>{content.title}</title>
        <meta name="description" content={content.description}/>
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`${withPrefix('/')}img/apple-touch-icon.png`}
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix('/')}img/favicon-32x32.png`}
          sizes="32x32"
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix('/')}img/favicon-16x16.png`}
          sizes="16x16"
        />
        <link
          rel="mask-icon"
          href={`${withPrefix('/')}img/safari-pinned-tab.svg`}
          color="#ff4400"
        />
        <meta name="msapplication-TileColor" content="#da532c"/>
        <meta name="theme-color" content="#ffffff"/>
        <meta name="theme-color" content="#B2B2B2"/>

        <meta property="og:type" content="business.business"/>
        <meta property="og:title" content={content.title}/>
        <meta property="og:url" content="/"/>
        <meta
          property="og:image"
          content={`${withPrefix('/')}img/android-chrome-384x384.png`}
        />
      </Helmet>
      <div>
        {children}
        {!noNav &&
        <>
          <OvalNav title={title}/>
          {
            !noDummy &&
            <div className="nav-dummy"/>
          }
          <noscript>
            Please enable JavaScript to navigate this page
          </noscript>
        </>
        }
        {
          backButton &&
          <BackButton/>
        }
      </div>
    </div>);
};

export default Layout
