import React from "react"

const Logo = ({}) => {
  return (
    <svg className="logo-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 718.29 186.32">
      <path d="M289.6,21c28.64,4.12,73.73,32.69,73.73,86.05,0,47.91-54.42,85.06-154.94,85.06"
            transform="translate(-12.81 -13.31)" fill="none" stroke="lime" strokeMiterlimit="10" strokeWidth="14" />
      <path
        d="M211.05,189.84a2.69,2.69,0,0,1-2.66,2.29H80.94c-1.55,0-2.22-2.06-2.22-3.2L175.42,63H106.16V90.61c0,2.28-.66,5.25-2.88,5.25H28.05c-2.66,0-7.74.45-7.74-3v-68c0-1.6,1.76-3.88,3.31-3.88h266c2.88,0,4.43,2.28,2.88,6.16Z"
        transform="translate(-12.81 -13.31)" fill="none" stroke="lime" strokeMiterlimit="10" strokeWidth="14" />
      <path d="M309.26,141.63" transform="translate(-12.81 -13.31)" fill="none" stroke="lime" strokeMiterlimit="10"
            strokeWidth="14" />
      <path d="M552.92,192.13H427.4c-73,0-124.35-36-124.35-83.95,0-62.9,54.44-85.46,112.71-87.16l126-.21M443.14,149.69"
            transform="translate(-12.81 -13.31)" fill="none" stroke="lime" strokeMiterlimit="10" strokeWidth="14" />
      <path
        d="M584.06,21c80.45,2.1,134.29,22.58,134.29,42,1.09,10.86-1,29.12-31.7,19.28-58-20.37-94.54-18.73-109.29-19.05s-52.47,5.93-52.47,45.63c15.28-15.06,62-25.17,99.23-22.36,24.45,2.16,99.47,10.72,99.47,52.47,0,40.15-61.05,52-97,53.16H552.26c-73,0-152.16-27.64-152.16-83.95,0-61.83,79.16-87.36,140.53-87.36Zm17.55,117.79c0-11.42-17.11-14.14-34-14.14-16.66,0-32.17,2.27-32.17,14.14s18.48,13.68,35.36,13.68C585.19,152.5,601.61,150.22,601.61,138.82Z"
        transform="translate(-12.81 -13.31)" fill="none" stroke="lime" strokeMiterlimit="10" strokeWidth="14" />
      <path
        d="M328.87,192.13c-73,0-152.16-36-152.16-83.95,0-61.83,90.8-87.16,152.16-87.16s150.2,25.33,150.2,87.16c0,47.9-79.15,83.95-152.15,83.95"
        transform="translate(-12.81 -13.31)" fill="none" stroke="lime" strokeMiterlimit="10" strokeWidth="14" /></svg>
  )
}

export default Logo
