import React from 'react';
import PropTypes from 'prop-types'
import {Link} from "gatsby"
import Logo from "./Logo";

const SMALL_WIDTH = 250;
const SMALL_HEIGHT = 50;
const TALL_HEIGHT = 300;
const MAX_PROGRESS = TALL_HEIGHT - SMALL_HEIGHT;
const LINE_WIDTH = 4;
const CHANGE_DELTA = 25;

class OvalNav extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      ready: false,
      active: false,
      loading: false,
      touchActivated: false,
      fullyOpen: false
    }
    this.canvasEl = React.createRef();
    this.overlayEl = React.createRef();
    this.wrapperEl = React.createRef();
    this.currentHeight = SMALL_HEIGHT;
  }

  componentDidMount() {
    this.draw();
    window.requestAnimationFrame(this.check)
    // window.addEventListener('touchstart', this.handleTouch)
  }

  componentWillUnmount() {
    // window.removeEventListener('touchstart', this.handleTouch)
  }

  handleMouseEnter = () => {
    this.setState({active: true})
  }

  handleMouseLeave = () => {
    if (!this.state.touchActivated) {
      this.setState({active: false})
    }
  }

  handleTouch = () => {
    this.setState({active: true, touchActivated: true})
  }

  handleCloseTouch = () => {
    this.setState({active: false, touchActivated: false})
  }

  handleLinkClick = e => {
    this.setState({loading: true})
  }

  check = () => {
    // exit in case of unmount
    if (!this.canvasEl.current) return

    // Animate to big
    if (this.state.active && !this.state.loading && this.currentHeight < TALL_HEIGHT) {
      this.currentHeight += CHANGE_DELTA;
      if (this.currentHeight > TALL_HEIGHT) {
        this.currentHeight = TALL_HEIGHT
      }
      this.draw();
    }

    // Animate to small
    if ((!this.state.active || this.state.loading) && this.currentHeight > SMALL_HEIGHT) {
      this.currentHeight -= CHANGE_DELTA;
      if (this.currentHeight < SMALL_HEIGHT) {
        this.currentHeight = SMALL_HEIGHT
      }
      this.draw();
    }

    // Check if fully open
    if (!this.state.fullyOpen && this.currentHeight === TALL_HEIGHT) {
      this.setState({
        fullyOpen: true
      })
    }

    // Check if not fully open
    if (this.state.fullyOpen && this.currentHeight < TALL_HEIGHT) {
      this.setState({
        fullyOpen: false
      })
    }

    window.requestAnimationFrame(this.check)
  }

  draw = () => {
    const canvas = this.canvasEl.current;
    const overlay = this.overlayEl.current;
    const ctx = canvas.getContext('2d');

    const width = SMALL_WIDTH * window.devicePixelRatio;
    const height = this.currentHeight * window.devicePixelRatio;
    const colorProgress = 1 - ((TALL_HEIGHT - this.currentHeight) / MAX_PROGRESS);

    canvas.style.width = `${SMALL_WIDTH}px`;
    canvas.style.height = `${this.currentHeight}px`;
    overlay.style.width = `${SMALL_WIDTH}px`;
    overlay.style.height = `${this.currentHeight}px`;
    canvas.width = width;
    canvas.height = height;

    // Draw the ellipse
    ctx.beginPath();
    ctx.strokeStyle = `rgb(${255 * colorProgress},255,${255 * colorProgress})`;
    ctx.fillStyle = `rgba(0,0,0,${colorProgress})`;
    ctx.lineWidth = LINE_WIDTH;
    ctx.ellipse(
      width / 2,
      height / 2,
      (width / 2) - LINE_WIDTH,
      (height / 2) - LINE_WIDTH,
      0,
      0,
      2 * Math.PI
    );
    ctx.fill();
    ctx.stroke();

    if (!this.state.ready) {
      this.setState({ready: true})
    }
  }

  render() {
    return (
      <>
        <div
          className="oval-nav-wrapper"
          onMouseEnter={this.handleMouseEnter}
          onMouseLeave={this.handleMouseLeave}
          onTouchStart={this.handleTouch}
          ref={this.wrapperEl}
          style={{
            display: this.state.ready ? 'block' : 'none'
          }}
        >
          <canvas
            ref={this.canvasEl}
            className="oval-nav-canvas"
          />

          <div
            className="oval-content"
            ref={this.overlayEl}
          >
            {this.state.active && !this.state.loading &&
            <nav
              className="oval-nav-items"
              style={{
                pointerEvents: this.state.fullyOpen ? 'all' : 'none'
              }}
            >
              <Link onClick={this.handleLinkClick} activeClassName="active" to="/">76666</Link>
              <Link onClick={this.handleLinkClick} activeClassName="active" to="/releases">Releases</Link>
              <Link onClick={this.handleLinkClick} activeClassName="active" to="/samplers">Samplers</Link>
              <Link onClick={this.handleLinkClick} activeClassName="active" to="/mixes">Mixes</Link>
              {/*<Link onClick={this.handleLinkClick} activeClassName="active" to="/library">Library</Link>*/}
              <Link onClick={this.handleLinkClick} activeClassName="active" to="/events">Events</Link>
              <Link onClick={this.handleLinkClick} activeClassName="active" to="/physicals">Physicals</Link>
            </nav>
            }
            {
              !this.state.active && !this.state.loading &&
              <span className="oval-nav-title">
                {this.props.title
                  ? this.props.title
                  : <Logo/>
                }
            </span>
            }
            {
              this.state.loading &&
              <span className="oval-nav-title">
              Loading...
            </span>
            }
          </div>

        </div>
        {this.state.touchActivated &&
        <div
          onClick={this.handleCloseTouch}
          className="nav-back-overlay"
        />}
      </>
    )
  }
}

OvalNav.propTypes = {
  title: PropTypes.string
}

OvalNav.defaultProps = {
  title: ""
}

export default OvalNav;
